
import Form from '../Form'
import SelectInput from './Select.vue'
import TextInput from './Text.vue'
import ComboInput from './Combo.vue'
import SwitchInput from './Switch.vue'
import DateInput from './Date.vue'
import Vue from 'vue'
import { InputInterface } from '../interface'
export default Vue.extend({
    components:{
        SelectInput ,
        ComboInput,
        SwitchInput,
        DateInput,
        TextInput
    },
    props:{
        form:Form
    },
    methods:{
        validate():boolean{
            if(this.form.hasValidation == false){
                return true
            }
            for (let index = 0; index < this.form.inputs.length; index++) {
                const input = this.form.inputs[index];
                if(input.field.required == true && ( input.field.val == null || typeof input.field.val == 'undefined')){
                    return false
                }
                
            }
            return true
        },
        // this method will be code when any input in the form changed
        change(input:InputInterface) {
            this.form.valid = false
            let form =  this.form.state
            form[input.name as keyof typeof form] = input.val
            if(this.validate()){
                this.form.valid = true
                this.$emit('change' , input.val)
            }
        }
    }
})
