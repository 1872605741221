
import Vue from "vue";
export default Vue.extend({
  props: {
    input: Object,
  },
  methods:{
      change(val:any){
        console.log(val)
        console.log(this.input.value)
        this.input.val = val[this.input.value]
        console.log(this.input.val)
          this.$emit('change' , val)
      }
  }
});
