
import Vue from "vue";
import { InputInterface } from "../interface";
export default Vue.extend({
  props: {
    input: Object,
  },
  methods:{
      change(val:any){
        if(typeof this.input.change != 'undefined'){
          this.input.change(val)
        }
        this.input.val = val
        this.$emit('change')
      }
  }
});
