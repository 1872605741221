
import Datatable from "@/utils/datatable/datatable";
import { Header } from "@/utils/datatable/datatableInterface";
import { currency } from "@/utils/helpers";
import AppForm from '@/utils/form/components/Form.vue'
import {baseImg} from '@/utils/helpers'
import Vue from "vue";
import {Delete} from '@/repositories/global'
export default Vue.extend({
  props: {
    table: Datatable,
  },
  data() {
    return {
      baseImg,
      dialog : false
    }
  },
  components:{
    AppForm
  },
  computed:{
    totalsHeaders(){
      return this.table.headers.filter((header:Header) => {
        return header.isTotal ? header : ''
      })
    },
  },
  methods: {
    getDayOffs(days: string) : string{
      const daysArr = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ]
      let result = ""
      days.split(',').forEach(day => {
        console.log(parseInt(day) -1)
        result += `${daysArr[parseInt(day) - 1]} ,`
      });
      return result.slice(0, -1)
    },
    currency: (x: number) => currency(x),
    remove(id:number){
      const type = this.$route.path.replace('/' , '')
      
      Delete(type , id).then(() => {
        this.table.getData()
      })
    },  
    filter(val:any){
      // reset headers totals to avoid sum bug
      // if we dont do this the class will add the totals to thee preevios data totals
      this.table.headers.forEach((header:Header) => {
        header.total = 0
      })
        this.table.getData()
    },
    showTotals(){
      this.dialog=true
    }
  },
});
